import React from 'react';
import styles from './404.module.css';
import DocsLayout from 'src/layout/DocsLayout';

const NotFoundPage: React.FC = () => {
	return (
		<DocsLayout>
			<div className={styles.Container}>
				<h1 className={styles.Title}>404</h1>
				<h3 className={styles.SubTitle}>Page not found</h3>
			</div>
		</DocsLayout>
	);
};

export default NotFoundPage;
